import React,{useEffect} from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TestimonialCard from "../components/TestimonialCard";

function Testimonials() {
  const handleScroll = () => {
    window.scrollBy({
      top: 550,
      left: 0,
      behavior: "smooth",
    });
  };

  const users = [
    { id: 1,category:"UMRAH",avatar:true, name: " Rehan",date:"January",star:5,comment:"I had a great experience with this travel agency for my Umrah trip. They arranged everything, from flights and transport to hotels, and the customer service was very helpful. Highly recommend their services" },
    { id: 2,category:"UMRAH",avatar:false, name: "Tayyaba S",date:"May",star:5,comment:"Recently I had a travel through Ocean Breeze Travel…Recently I had a travel through OCEAN BREEZE TRAVEL, the experience was amazing from beginning to end ,,, when I visit for informations MUHAMMAD ASHIR helped me very honestly with very polite and calm nature .....your behave can change anything and this guy have this potential .....amazing experience will keep you in touch whenever I need again however trip was amazing too experience with OCEAN BREEZE TRAVEL was memorable ....thanks for your kind help ASHIR" },
    { id: 3,category:"UMRAH",avatar:true, name: "Shoaib A",date:"Feburary",star:5,comment:"Great Umrah experience at Makkah and then Madinah From start to returning back to London UK, my family experienced a positive enjoyment without losses of money or time. At Jeddah, we were received by their taxi to drop us at Makkah Hotel to start our Umrah. We were picked up at Makkah to go to the second stage of our important Umrah ( the spiritual and important pilgrimage of life). At both Makkah and Madinah, the Masjid Al Haram ( Main Prayer Halls) is in walkable distance and visible and audible range of Azaahn and live Prayers at the most important places of Islam. Prices were fixed and were very moderate ! Very reliable services with great value for money.Thank you so much 🥰 May the Almighty Allah bless you and your family/ teams." },
    { id: 4,category:"UMRAH",avatar:true, name: "Abdul H",date:"March",star:5,comment:"I Recently booked a complete Umrah package with Ocean Breeze Travel. The Agent that attended to my booking was Mr Ashir azeem he was extremely helpful, attentive to all of my queries and concerns. Voco Hotel Makkah was perfect 5**** hotel in service with shuttle Service Madinah Hotel was on Walking Distance" },
   
  ];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);


  return (
    <Wrapper>
      <Header />
      <div className="upper-divs">
        <div className="holders">
          <div className="large-text">
           Testimonials
          </div>
          <KeyboardArrowDownIcon className="down-icon" onClick={handleScroll} />
          <div className="blur-bg" />
        </div>
      </div>

      <div className="review-outer">
        <div className="review-main">
          {users.map((user) => (
            <TestimonialCard key={user.id} category={user.category} avatar={user.avatar} date={user.date} star={user.star} comment={user.comment} name={user.name} />
          ))}
        </div>
      </div>

      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;

  .bg-image {
    height: 100%;
    width: 100%;
    z-index: -2;
    position: absolute;
    object-fit: cover;
  }

  .review-outer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }
  .review-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;

    justify-content: space-between;
    width: 80%;
  }

  .blur-bg {
    height: 100%;
    width: 100%;
    background-color: black;
    position: absolute;
    z-index: -1;
    opacity: 0.5;
  }
  .large-text {
    font-size: 35px;
    width: 70%;
    text-align: center;
    color: white;
    margin-top: 7.5%;
    font-family: "Montserrat", sans-serif;
  }

  .upper-divs {
    height: 70vh;
    width: 100%;
  }
  .down-icon {
    font-size: 75px;
    margin-top: 45px;
    color: white;
    background-color: #1328bc;
    border-radius: 50%;
    transition: all 0.3s ease;
    background-color: #183b7d;

  }
  .down-icon:hover {
    cursor: pointer;
    background-color: white;
    transition: all 0.3s ease;
    color: #1328bc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
  }

  .holders {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #0b155f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1f4995;

  }
  @media (max-width: 768px) {
    .upper-divs {
      height: 70vh;
      width: 100%;
      clip-path: none;
    }

    .holders {
      clip-path: none;
    }

    .large-text {
      font-size: 20px;
      width: 95%;
      font-family: "Montserrat", sans-serif;
    }
  }
`;
export default Testimonials;
