import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Intrestcard from "./Intrestcard";

function Cardholder() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="main">
        <div className="cardholder">
          <Intrestcard
            title={"Umrah 2025 / 1446"}
            subtitle={"Register Your Intrest"}
            image={
              "https://st.depositphotos.com/1003580/1217/i/450/depositphotos_12179888-stock-photo-makkah-kaaba-door-with-verses.jpg"
            }
          />
          <Intrestcard
            title={"Hajj 2025 / 1446"}
            subtitle={"Register Your Intrest"}
            image={
              "https://st2.depositphotos.com/36891442/45754/i/600/depositphotos_457547450-stock-photo-muslims-praying-in-nabawi-mosque.jpg"
            }
          />
          <Intrestcard
            title={"Holiday 2025 / 1446"}
            subtitle={"Register Your Intrest"}
            image={
              "https://images.pexels.com/photos/38238/maldives-ile-beach-sun-38238.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            }
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  background-color: white;
  clip-path: polygon(
    69% 7.9%, 
    100% 10%, 
    100% 40%, 
    100% 100%, 
    68% 100%, 
    32% 100%, 
    0 100%, 
    0 10%, 
    26% 7.9% 
);
  .main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    
  }
  .cardholder {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 300px;
  }

  @media (max-width: 768px) {
    .cardholder {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  clip-path: none;
  }
`;
export default Cardholder;
