import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function Intrestcard(props) {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="main" onClick={() => navigate("/ContactUs")}>
        <img src={props.image} className="image" />
        <div className="textholder">
          <div className="upper">
            <div className="inner">
              <div className="subtext">{props.title}</div>
            </div>
          </div>
          <div className="lower">
            <div className="backgroung"></div>
            <div className="maintext">{props.subtitle}</div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 440px;
  width: 30%;
  position: relative;
  cursor: pointer;
  .main {
    height: 100%;
    width: 100%;
    position: relative;
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.3);
  }
  .image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .textholder {
    height: auto;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .upper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .inner {
    width: 80%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #d9078f;
  }
  .lower {
    width: 100%;
    height: 60px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: white;
    border-top: 6px solid #d9078f;
    transition: background-color 0.3s ease;
    transition: border-top 0.5s ease;
  }
  .backgroung {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 1;
    transition: transform 0.3s ease;
    transform-origin: bottom;
    transform: scaleY(1);
  }

  .main:hover .backgroung {
    transform: scaleY(0);
    transition: transform 0.3s ease;
  }
  .main:hover .lower {
    background: none;
    border-top: none;
    transition: background-color 0.5s ease;
    transition: border-top 0.4s ease;
  }
  .subtext {
    font-size: 19px;
    /* font-family: "Teachers", sans-serif; */
    font-optical-sizing: auto;
    font-weight: bold;
    color: white;
    font-style: normal;
  }
  .maintext {
    font-size: 21px;
    /* font-family: "Teachers", sans-serif; */
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    color: grey;
    z-index: 2;
    padding: 3%;
  }
  .main:hover .maintext {
    color: white;
  }

  @media (max-width: 768px) {
    height: 400px;
  width: 90%;
  position: relative;
  margin-top: 10%;
  }
`;

export default Intrestcard;
