import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styled from "styled-components";
import Selector from "../components/Selector";
import Cardholder from "../components/Cardholder";
import NewscardHolder from "../components/NewscardHolder";
import Reviews from "../components/Reviews";
import WhyChoose from "../components/WhyChoose";
import Ourpartners from "../components/Ourpartners";
import Gallery from "../components/Gallery";
import Temperature from "../components/Temperature";
import image from "../photo-1591604159758-680f22f77d24.avif";

function Hero() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Header />
      <div className="imageHolder">
        <div className="opacity" />
        <div className="TextHolder">
          <div className="tagline">
            Our experience <br /> makes yours
          </div>
        </div>
        <img className="Image" src={image} />
      </div>
      <div className="selectorHolder">
        <Selector className="selector" />
      </div>
      <div className="cardholder">
        <Cardholder />
      </div>
      <div className="cardholder">
        <NewscardHolder />
      </div>
      <div className="reviewsholder">
        <Reviews />
      </div>
      <div className="reviewsholder">
        <WhyChoose />
      </div>
      <div className="reviewsholder">
        <Ourpartners />
      </div>
      <div className="reviewsholder">
        <Gallery />
      </div>
      <div className="reviewsholder">
        <Temperature />
      </div>
      <Footer />
    </Wrapper>
  );
}
const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: relative;

  .selectorHolder {
    position: relative;
    bottom: 100px;
  }
  .cardholder {
    position: relative;
    bottom: 110px;
  }
  .reviewsholder {
    position: relative;
  }

  .imageHolder {
    position: relative;
  }
  .opacity {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #1f4995;
    opacity: 0.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .TextHolder {
    position: absolute;
    height: 100%;
    width: 100%;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px 50px 0 0;
  }
  .tagline {
    color: white;
    font-size: 85px;
    font-family: "EB Garamond", serif;
    text-align: center;
  }
  .Image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    .imageHolder {
      position: relative;
      height: 100vh;
    }
    .tagline {
      font-size: 75px;
      margin-top: 30%;
    }
    .TextHolder {
    position: absolute;
    height: 100%;
    width: 100%;
    background: none;
    display: flex;
    justify-content: center;
    align-items: start;
  }
  }
`;
export default Hero;
