import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import ReviewCard from "./ReviewCard";
import TestimonialCard from "./TestimonialCard";
function Reviews() {
  const navigate = useNavigate();


  const users = [
    { id: 1,category:"UMRAH",avatar:true, name: " Rehan",date:"January",star:5,comment:"I had a great experience with this travel agency for my Umrah trip. They arranged everything, from flights and transport to hotels, and the customer service was very helpful. Highly recommend their services" },
    { id: 2,category:"UMRAH",avatar:false, name: "Tayyaba Shahzadi",date:"May",star:5,comment:"Recently I had a travel through Ocean Breeze Travel…Recently I had a travel through OCEAN BREEZE TRAVEL, the experience was amazing from beginning to end ,,, when I visit for informations MUHAMMAD ASHIR helped me very honestly with very polite and calm nature .....your behave can change anything and this guy have this potential .....amazing experience will keep you in touch whenever I need again however trip was amazing too experience with OCEAN BREEZE TRAVEL was memorable ....thanks for your kind help ASHIR" },
    { id: 3,category:"UMRAH",avatar:true, name: "Shoaib Ali",date:"Feburary",star:5,comment:"Great Umrah experience at Makkah and then Madinah From start to returning back to London UK, my family experienced a positive enjoyment without losses of money or time. At Jeddah, we were received by their taxi to drop us at Makkah Hotel to start our Umrah. We were picked up at Makkah to go to the second stage of our important Umrah ( the spiritual and important pilgrimage of life). At both Makkah and Madinah, the Masjid Al Haram ( Main Prayer Halls) is in walkable distance and visible and audible range of Azaahn and live Prayers at the most important places of Islam. Prices were fixed and were very moderate ! Very reliable services with great value for money.Thank you so much 🥰 May the Almighty Allah bless you and your family/ teams." },
    { id: 4,category:"UMRAH",avatar:true, name: "Abdul Hameed",date:"March",star:5,comment:"I Recently booked a complete Umrah package with Ocean Breeze Travel. The Agent that attended to my booking was Mr Ashir azeem he was extremely helpful, attentive to all of my queries and concerns. Voco Hotel Makkah was perfect 5**** hotel in service with shuttle Service Madinah Hotel was on Walking Distance" },
   
  ];

  return (
  
    <Wrapper>
      <div className="title">Reviews</div>
      <div className="main">
        <div className="leftblur"></div>
        <div className="rightblur"></div>

        <div className="cardholder">
          <Swiper
             slidesPerView={1}
             centeredSlides={true}
             spaceBetween={30}
            
             navigation={true}
             modules={[Pagination]}
             className="mySwiper"
             breakpoints={{
               768: {
                 slidesPerView: 2,
               },
             }}
          >
           {users.map((user) => (
        <SwiperSlide key={user.id}>
          <ReviewCard
            category={user.category}
            avatar={user.avatar}
            name={user.name}
            date={user.date}
            star={user.star}
            comment={user.comment}
          />
        </SwiperSlide>
      ))}
          </Swiper> 
          {/* {users.map((user) => (
        <SwiperSlide key={user.id}>
<TestimonialCard key={user.id} category={user.category} avatar={user.avatar} date={user.date} star={user.star} comment={user.comment} name={user.name} />        </SwiperSlide>
      ))}
      </Swiper> */}
        </div>

        <div className="button" onClick={() => navigate("/Testimonials")}>
          <div className="buttontext">READ ALL REVIEW</div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
  position: relative;
 



  /* clip-path: polygon(
    69% 7.9%,
    100% 10%,
    100% 40%,
    100% 100%,
    68% 100%,
    32% 100%,
    0 100%,
    0 10%,
    26% 7.9%
  ); */
  .buttontext {
    text-align: center;
    font-weight: 500;
    letter-spacing: 2px;
    transition: all 0.3s;

  }
  .button {
    height: 13px;
    width: 170px;
    border: 2px solid black;
    padding: 1%;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: all 0.3s;
    transition: border 0.1s;
  }
  .button:hover .buttontext {
    color: white;
    transition: all 0.3s;
    cursor: pointer;

}

.button:hover  {
  border: none;
  background-color: #D90479;
  transition: all 0.3s;
  transition: border 0.1s;
  cursor: pointer;

}
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-bottom: 5%;
    z-index: 5;
  }

  .leftblur {
    height: 50%;
    width: 15%;
    background-color: white;
    position: absolute;
    left: 0px;
    z-index: 10;
    filter: blur(130px);
  }
  .rightblur {
    height: 50%;
    width: 15%;
    background-color: white;
    position: absolute;
    right: 0px;
    z-index: 10;
    filter: blur(150px);
  }
  .title {
    font-size: 70px;
    color: #454a46;
    text-align: center;
    font-family: "EB Garamond", serif;
    letter-spacing: 2px;
    padding-top: 10%;
  }
  .cardholder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 150px;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide {
    width: 60%;
  }
`;
export default Reviews;
