import React from "react";
import styled from "styled-components";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
function Temperature() {
  return (
    <Wrapper>
      <div className="main">
        <div className="inner">
          <div className="inner-left">
            <img
              src="https://images.pexels.com/photos/17582455/pexels-photo-17582455/free-photo-of-makkah-clock-royal-tower-mecca-saudi-arabia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              className="image"
            />
            <div className="blur-background" />
            <div className="textholder">
              <div className="maintext">Makkah</div>
              <div className="divider" />
              <div className="datetime">
                Wednesday 15th May 2024 | Local Time: 18:29
              </div>

              <div className="icon-temp-holder">
                <CloudQueueIcon className="icon" />
                <div className="temp-text">32 c</div>
              </div>
            </div>
          </div>
          <div className="inner-right">
          <img
              src="https://images.pexels.com/photos/2291789/pexels-photo-2291789.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              className="image"
            />
            <div className="blur-background" />
            <div className="textholder">
              <div className="maintext">Madinah</div>
              <div className="divider" />
              <div className="datetime">
                Wednesday 15th May 2024 | Local Time: 18:29
              </div>

              <div className="icon-temp-holder">
                <CloudQueueIcon className="icon" />
                <div className="temp-text">28 c</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;

  .main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .inner {
    height: 400px;
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .inner-left {
    height: 100%;
    width: 45%;
    position: relative;
    box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.3);
  }
  .inner-right{
    height: 100%;
    width: 45%;
    position: relative;
    box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.3);
  }
  .textholder {
    position: absolute;
    bottom: 0px;
    left: 10%;
    z-index: 5;
    color: white;
    padding: 15px;
  }
  .maintext{
    font-size: 70px;
    font-weight: 700;
  }
  .divider{
    height: 1px;
    width: 100%;
    background-color: white;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .datetime{
    font-size: 15px;
    letter-spacing: 2px;
  }
  .icon-temp-holder{
    display: flex;  
}
.icon{
    font-size: 80px;
}
.temp-text{
    font-size: 60px;
    margin-left: 10px;
}
  .blur-background {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: grey;
    opacity: 0.3;
  }
  .image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .inner-right {
    height: 100%;
    width: 45%;
  }
  
  @media (max-width: 768px) {
    .inner {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .inner-left {
    width: 90%;
    height: 550px;
    
  }
  .inner-right {
    margin-top: 5%;
    width: 90%;
    
    height: 550px;
  }
  .inner {
    height: 750px;
    width: 90%;
    height: 100%;
    margin-top: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 200px;

  }
  .textholder {
    position: absolute;
    bottom: 0px;
    left: 2%;
    z-index: 5;
    color: white;
    padding: 15px;
  }
  }
`;
export default Temperature;
