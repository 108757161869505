import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Hero from "./pages/Hero.js";
import ContactUs from "./pages/ContactUs.js";
import Testimonials from "./pages/Testimonials.js";
import HalalHoliday from "./pages/HalalHoliday.js";
import Hajj from "./pages/Hajj.js";
import Umrah from "./pages/Umrah.js";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Testimonials" element={<Testimonials />} />
        <Route path="/HalalHoliday" element={<HalalHoliday />} />
        <Route path="/Hajj" element={<Hajj />} />
        <Route path="/Umrah" element={<Umrah />} />

      </Routes>
    </Router>
  );
}

export default App;

