import React, { useState,useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { IoMenu } from "react-icons/io5";


import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import homeIcon from "../home.png";
import UmrahIcon from "../umrah.png";
import HajjIcon from "../hajj.png";
import holidayIcon from "../holiday.png";
import testimonialIcon from "../testimonials.png";
import contactIcon from "../contact us.png";

import { AiFillTikTok } from "react-icons/ai";
import InstagramIcon from '@mui/icons-material/Instagram';

import finallogo from "../../src/Final Png.png"

import logoImages from "../Final Png.png"
const Header = () => {
  const navigate = useNavigate();

  const [isScrolling, setisScrolling] = useState(false);

  window.addEventListener("scroll", function () {
    const scrollY = window.scrollY;
    if (scrollY <= 0) {
      setisScrolling(false);
    } else {
      setisScrolling(true);
    }
  });

  const handleButtonClick = () => {
    navigate('/ContactUs');
  };

  
  const handleTestButtonClick = () => {
    navigate('/Testimonials');
  };

  
  const handleHolidayButtonClick = () => {
    navigate('/HalalHoliday');
  };

  const handleHajjButtonClick = () => {
    navigate('/Hajj');
  };

  const handleUmrahButtonClick = () => {
    navigate('/Umrah');
  };

  const handleHomeButtonClick = () => {
    navigate('/');
  };



  const [isScrollDisabled, setIsScrollDisabled] = useState(false);

  useEffect(() => {
    if (isScrollDisabled) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isScrollDisabled]);

  const toggleScroll = () => {
    setIsScrollDisabled(!isScrollDisabled);
  };


  return (
    <Wrapper>
      <div className="screen">
      <div className="topline"></div>
      <div
        className={
          isScrolling
            ? "Opacitybackground-scrolling-class"
            : "Opacitybackground"
        }
      ></div>

      <div className={isScrolling ? "Inner-scrolling-class" : "Inner"}>
        <div className="socialmedia">
          <div className="iconInner">

            <a
            href="https://www.facebook.com/OceanBreezeTraveluk/">
               <FacebookIcon
              className={!isScrolling ? "icon" : "icon-scrolling-class"}
            />
            </a>

            <a
            href="https://www.instagram.com/oceanbreezetraveltd/">
                <InstagramIcon
              className={!isScrolling ? "icon" : "icon-scrolling-class"}
            />
            </a>

            <a
            href="https://www.tiktok.com/@oceanbreezetraveluk">
               <AiFillTikTok
              className={!isScrolling ? "icon" : "icon-scrolling-class"}
            />
            </a>

            <a
            href="https://www.youtube.com/channel/UCqprWf12hbxmK4BrxDfCtCg">
              <YouTubeIcon
              className={!isScrolling ? "icon" : "icon-scrolling-class"}
            />
            </a>
           
           
           
            



          </div>
        </div>
        <div className="name">
          {
            isScrolling ? <>
            <div className="logoImagesHolder">
              <img src={logoImages} className="logoImages"/>
              </div>
            </> : <>
            <div className="nonscrolling">
          <div className={isScrolling ? "title-scrolling-class" : "title"}>
            Ocean Breeze
          </div>
          <div
            className={isScrolling ? "subtitle-scrolling-class" : "subtitle"}
          >
            Travel
          </div>
          </div>
            </>
          }
         
        </div>
        <div className="contact">
          <div className={isScrolling ? "number-scrolling-class" : "number"}>
            Call us <span className="digits">020 3504 3077</span>
          </div>
        </div>
      </div>

      <div className={isScrolling ? "navigator-scrolling-class" : "navigator"}>
        <div className="navigatorInner">
          <div className={isScrolling ? "button-scrolling-class" : "button"} onClick={handleHomeButtonClick}>
            Home
          </div>
          <div className="dividerHolder">
            <div
              className={isScrolling ? "divider-scrolling-class" : "divider"}
            ></div>
          </div>

          <div className={isScrolling ? "button-scrolling-class" : "button"} onClick={handleUmrahButtonClick}>
            Umrah
          </div>
          <div className="dividerHolder">
            <div
              className={isScrolling ? "divider-scrolling-class" : "divider"}
            ></div>
          </div>
          <div className={isScrolling ? "button-scrolling-class" : "button"} onClick={handleHajjButtonClick}>
            Hajj
          </div>
          <div className="dividerHolder">
            <div
              className={isScrolling ? "divider-scrolling-class" : "divider"}
            ></div>
          </div>
          <div className={isScrolling ? "button-scrolling-class" : "button"} onClick={handleHolidayButtonClick}>
            {" "} 
            Holiday
          </div>
          <div className="dividerHolder">
            <div
              className={isScrolling ? "divider-scrolling-class" : "divider"}
            ></div>
          </div>
          <div className={isScrolling ? "button-scrolling-class" : "button"} onClick={handleTestButtonClick}>
            Testimonials
          </div>
          <div className="dividerHolder">
            <div
              className={isScrolling ? "divider-scrolling-class" : "divider"}
            ></div>
          </div>
       
          <div className={isScrolling ? "button-scrolling-class" : "button"} onClick={handleButtonClick}>
            Contact Us
          </div>
        </div>
      </div>
      </div>



      {!isScrollDisabled && (
        <div className="mobile-screen">
          <IoMenu className="menuIcon" onClick={toggleScroll} />
        </div>
      )}

      <img src={finallogo} className="mobile-logo"                   
      />
      {isScrollDisabled && (
        <div className="navigator">
          <CloseRoundedIcon className="cross-icon" onClick={toggleScroll} />

          <div className="navi-inner">
            <div className="navi-logo-holder">
              <div className="logo-inner">
                <img src={finallogo} className="navi-logo" />
              </div>
            </div>

            <div className="navi-buttons">
              <div className="navi-click" onClick={() => navigate("/")}>
                <img src={homeIcon} className="click-icon" />
                <div className="click-name">Home</div>
              </div>

              <div className="navi-click" onClick={() => navigate("/Umrah")}>
                <img src={UmrahIcon} className="click-icon" />
                <div className="click-name">Umrah</div>
              </div>

              <div className="navi-click" onClick={() => navigate("/Hajj")}>
                <img src={HajjIcon} className="click-icon" />
                <div className="click-name">Hajj</div>
              </div>

              <div className="navi-click" onClick={() => navigate("/HalalHoliday")}>
                <img src={holidayIcon} className="click-icon" />

                <div className="click-name">Holiday</div>
              </div>

              <div
                className="navi-click"
                onClick={() => navigate("/Testimonials")}
              >
                <img src={testimonialIcon} className="click-icon" />
                <div className="click-name">Testimonials</div>
              </div>


              <div
                className="navi-click"
                onClick={() => navigate("/ContactUs")}
              >
                <img src={contactIcon} className="click-icon" />{" "}
                <div className="click-name">Contact Us</div>
              </div>
            </div>

            <div className="divider" />
          </div>
        </div>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.section`
  height: 180px;
  width: 100%;
  position: fixed;
  z-index: 10;

  .Inner {
    height: 75%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: height 0.3s ease;
  }
  .screen{
    height: 100%;
    width: 100%;
  }
  .Inner-scrolling-class {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: height 0.3s ease;
  }
  .nonscrolling{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .socialmedia {
    height: 60%;
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    :hover {
      cursor: pointer;
    }
  }
  .navigator {
    height: 25%;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.3s ease;
  }
  .navigator-scrolling-class {
    height: 15%;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.3s ease;
  }
  .logoImagesHolder{
    height: 100%;
    width: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .logoImages{
    height: 130px;
    width: 130px;
         
  }
  .navigatorInner {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    width: 60%;
    height: 75%;
  }
  .button {
    color: white;
    font-weight: bold;
    font-size: 16px;
    :hover {
      cursor: pointer;
    }
    transition: color 0.3s ease;
    font-family: "Montserrat", sans-serif;
  }
  .button-scrolling-class {
    color: black;
    font-weight: bold;
    font-size: 16px;
    :hover {
      cursor: pointer;
    }
    transition: color 0.3s ease;
    :hover {
      cursor: pointer;
    }

  }
  .button:hover {
  cursor: pointer;
}
.button-scrolling-class:hover {
  cursor: pointer;
}
  .dividerHolder {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .divider {
    height: 50%;
    width: 1px;
    background-color: white;
    opacity: 0.55;
    transition: color 0.3s ease;
  }
  .divider-scrolling-class {
    height: 50%;
    width: 1px;
    background-color: black;
    opacity: 0.55;
    transition: color 0.3s ease;
  }

  .name {
    height: 100%;
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    transition: opacity 0.3s ease;
  }
  .name-scrolling-class {
    height: 60%;
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    transition: opacity 0.3s ease;
  }
  .contact {
    height: 60%;
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
  }
  .icon {
    color: whitesmoke;
    font-size: 26px;
    transition: color 0.3s ease;
  }
  .icon-scrolling-class {
    color: black;
    transition: color 0.3s ease;
    font-size: 26px;
  }

  .number {
    color: whitesmoke;
    font-size: 25px;
    transition: color 0.3s ease;
    font-family: "Montserrat", sans-serif;
  }
  .number-scrolling-class {
    color: black;
    font-size: 25px;
    transition: color 0.3s ease;
  }
  .digits {
    font-weight: bold;
  }
  .title {
    color: white;
    font-size: 50px;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: color 0.3s ease;
  }
  .title-scrolling-class {
    color: black;
    font-size: 35px;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: color 0.3s ease;
  }

  .subtitle {
    color: white;
    font-size: 40px;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: font 0.2s ease;
  }
  .subtitle-scrolling-class {
    color: black;
    font-size: 0px;
    transition: font 0.2s ease;
  }
  .iconInner {
    width: 140px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .Opacitybackground-scrolling-class {
    position: absolute;
    height: 80%;
    width: 100%;
    opacity: 1;
    background: white;
    z-index: -1;
    transition: color 0.3s ease;
    transition: height 0.3s ease;
  }
  .Opacitybackground {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.4;
    z-index: -1;
    transition: color 0.3s ease;
    transition: height 0.3s ease;
  }
  .topline {
    height: 09px;
    width: 100%;
    background-color: #de007a;
  }
  .mobile-logo {
    display  :none ;
  }
  @media (max-width: 768px) {

  position: relative;
    height: 60px;
    background-color: #1f4995;
    margin-bottom: 0px;
    margin-top: 0px;


    .screen {
      display: none;
    }
    .mobile-screen {
      display: flex;
      align-content: center;
      align-items: center;
      background-color: #1f4995;
      background-color: white;
    }
    .menuIcon {
      color: #1f4995;
      font-size: 50px;
      margin-top: 4px;
    }
    .mobile-logo {
      display: grid;
      position: absolute;
      height: 60px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .drawer {
      background-color: black;
    }
    .navigator {
      position: absolute;
      background-color: white;
      height: 100vh;
      width: 100vw;
      background-color: #f2f2f2;
    }
    .cross-icon {
      position: absolute;
      right: 20px;
      top: 27px;
      font-size: 30px;
      color: #1328bc;
      z-index: 15;
    }
    .navi-inner {
      height: 100%;
      width: 100%;
      position: relative;
    }
    .navi-logo-holder {
      height: 80px;
      width: 100%;
      margin-top: 2%;
      position: relative;
      display: flex;
      justify-content: start;
      align-content: center;
      flex-direction: row;
    }
    .logo-inner {
      height: 100%;
      width: 100%;
      margin: 2%;
      display: flex;
      justify-content: start;
      position: relative;
    }
    .navi-logo {
      height: 55px;
      width: auto;
      margin-left: 7px;
    }

    .navi-buttons {
      height: auto;
      width: 100%;
      margin-top: 5%;
      position: relative;
    }
    .navi-click {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      padding: 2%;
      width: 45%;
      margin: 2%;
    }
    .click-icon {
      /* font-size: 35px; */
      height: 25px;
      width: 25px;
    }
    .click-name {
      font-size: 19px;
      width: 75%;
      font-weight: 500;
    }
    .divider {
      margin-top: 10%;
      height: 2px;
      width: 100%;
      background-color: #1328bc;
      opacity: 0.1;
    }
    .socials-navi {
      height: auto;
      margin-top: 5%;
      padding-left: 5%;
    }
    .navi-icon {
      font-size: 45px;
    }
    .navi-textholder {
      display: flex;
      align-content: center;
      align-items: center;
      margin-top: 10px;
    }
    .navi-icon-holder {
      margin-top: 5%;
    }

    .icon2 {
      font-size: 25px;
      color: #1328bc;
    }
    .navi-text {
      font-size: 17px;
      font-weight: 500;
      margin-left: 5px;
    }

  }
`;
export default Header;
