import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Selector = () => {
  const navigate = useNavigate();

  const handleHomeButtonClick = () => {
    navigate("/Umrah");
  };

  const handleUmrahButtonClick = () => {
    navigate("/Hajj");
  };

  const handleHajjButtonClick = () => {
    navigate("/HalalHoliday");
  };
  
  return (
    <Wrapper>
      <div className="textprompt">
        <div className="line"></div>
        <div className="text">A SIGNATURE OF EXCELLENCE</div>
        <div className="line"></div>
      </div>

      <div className="navigator">
        <div className="card">
          <div className="topline"></div>

          <div className="cardinner" onClick={handleHomeButtonClick}>
            <div className="title">Umrah</div>
            <div className="iconholder">
              <ArrowForwardIcon className="Icon" />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="topline"></div>
          <div className="cardinner" onClick={handleUmrahButtonClick}>
            <div className="title">Hajj</div>
            <div className="iconholder">
              <ArrowForwardIcon className="Icon" />
            </div>
          </div>
        </div>

        <div className="card">
          <div className="topline"></div>
          <div className="cardinner" onClick={handleHajjButtonClick}>
            <div className="title">Holiday</div>
            <div className="iconholder">
              <ArrowForwardIcon className="Icon" />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;

  .textprompt {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  .text {
    font-weight: bold;
    color: white;
    font-size: 17px;
    text-align: center;
  }
  .line {
    width: 30%;
    height: 1px;
    background-color: white;
    opacity: 0.9;
  }
  .navigator {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 70%;
    margin-top: 1%;
  }
  .card {
    height: 250px;
    width: 33%;
    background-color: #1f4995;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    position: relative;
    transition: transform 0.3s ease;
  }
  .card:hover .iconholder {
    background-color: #de007a;
  }
  .card:hover .icon {
    color: black;
    background-color: #de007a;
  }
  .card:hover {
    transform: translateY(-10px);
  }
  .cardinner {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 3%;
  }
  .iconholder {
    height: 50px;
    width: 50px;
    background-color: #2b519c;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Icon {
    font-size: 26px;
    opacity: 0.75;
    color: white;
  }
  .topline {
    height: 09px;
    width: 100%;
    background-color: #de007a;
    position: absolute;
    top: 0;
  }
  .title {
    text-align: center;
    font-size: 53px;
    color: #ecffff;
    font-family: "EB Garamond", serif;
  }

  @media (max-width: 768px) {

  .textprompt {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-bottom: 2%;
  }
  .text {
    font-size: 12px;
  }
  .navigator {
    width: 90%;
  }
  .title {
    text-align: center;
    font-size: 30px;
    margin-top: 15%;
  }

  .card {
    height: 350px;
  }
  .Icon {
    font-size: 26px;
    opacity: 0.75;
    color: white;
  }
  .iconholder {
    height: 50px;
    width: 50px;
    background-color: #2b519c;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    margin-bottom: 35px;
  
  }
  .line {
    width: 15%;
    height: 1px;
    background-color: white;
    opacity: 0.9;
  }
  }
`;

export default Selector;