import React from "react";
import styled from "styled-components";
import LayersIcon from "@mui/icons-material/Layers";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";

function TestimonialCard(props) {
  return (
    <Wrapper>
      <div className="wrapper">
        <div className="profilepic">
          {props.avatar ? (
            <>
              <img
                className="avatar"
                src="https://dometours.com/assets/site/img/avatar-male.png"
              />
            </>
          ) : (
            <>
              <img
                className="avatar"
                src="https://dometours.com/assets/site/img/avatar-female.png"
              />
            </>
          )}
        </div>
        <div className="sidebanner">
          <LayersIcon className="logo" />

          <div className="category">{props.category}</div>
          <div className="date">2024</div>
        </div>

        <div className="upper">
          <div className="name">{props.name}</div>
          <div className="reviewdate">{props.date}</div>
          <div className="starsholder">
            <Stack spacing={1}>
              <Rating
                className="star"
                name="half-rating"
                defaultValue={props.star}
                precision={0.5}
                readOnly={true}
              />
            </Stack>
          </div>
        </div>
        <div className="lower">
          <div className="reviewtext">{props.comment}</div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 500px;
  width: 48%;
  position: relative;
  box-shadow: 10px 50px 10px rgba(0, 0, 0, 0.03);
  /* margin: 1%; */
  background-color: white;

  .wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .starsholder {
    border: 1px solid rgb(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
  }
  .star {
    color: #f2cb05;
  }
  .name {
    color: #616161;
    font-size: 45px;
    font-family: SangBleuSans, serif;
  }
  .reviewdate {
    color: #616161;
    font-size: 17px;
    margin-bottom: 15px;
  }
  .sidebanner {
    background-color: #1328bc;
    height: 160px;
    width: 60px;
    position: absolute;
    top: 0;
    right: 30px;
    border-radius: 0px 0px 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .profilepic {
    height: 100px;
    width: 100px;
    background-color: pink;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: 50px;
  }
  .avatar {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  .logo {
    height: 50px;
    width: 50px;
    color: white;
    margin-top: 7px;
  }
  .date {
    color: white;
    font-weight: 400;
    font-size: 19px;
    letter-spacing: 1px;
  }

  .category {
    color: white;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 1px;
  }
  .upper {
    height: 30%;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .lower {
    height: 50%;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .reviewtext {
    font-size: 23px;
    text-align: center;
    color: #616161;
    font-weight: 100;
    font-style: italic;
    display: -webkit-box;
  -webkit-line-clamp: 8; /* Limit to 5 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    .logo {
      height: 50px;
      width: 50px;
    }
    .profilepic {
      height: 50px;
      width: 50px;
      top: 20px;
      left: 20px;
    }
    .lower {
      height: auto;
      width: 90%;
      margin-top: 10%;
    }
    .reviewtext {
      font-size: 17px;
    }

    .sidebanner {
      background-color: #1328bc;
      height: 100px;
      width: 45px;
      right: 10px;
    }

    .category {
      font-size: 10px;
    }
    .date {
      font-size: 14px;
    }
    .name {
      color: #616161;
      font-size: 25px;
      font-family: SangBleuSans, serif;
      margin-top: 30%;
    }
    .reviewdate {
      font-size: 17px;
      margin-bottom: 15px;
    }
  }
`;

export default TestimonialCard;
