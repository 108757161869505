import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TextsmsIcon from "@mui/icons-material/Textsms";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="topline"></div>

      <div className="contactHolder">
        <div className="contactInner">
          <div className="contactItem">
            <div className="iconHolder">
              <LocalPhoneIcon className="icon" />
            </div>
            <div className="textHolder">
              <div className="title">Call us</div>
              <div className="subtitle">020 3504 3077</div>
            </div>
          </div>

          <div className="contactItem">
            <div className="iconHolder">
              <TextsmsIcon className="icon" />
            </div>
            <div className="textHolder">
              <div className="title">Email us</div>
              <div className="subtitle">inquiry@oceanbreezetravel.co.uk</div>
            </div>
          </div>

          <div className="contactItem">
            <div className="iconHolder">
              <LocationOnIcon className="icon" />
            </div>
            <div className="textHolder">
              <div className="title">Visit us</div>
              <div className="subtitle">Almarkaz ul Islami Beckside Ln, Bradford BD7 2JX</div>
            </div>
          </div>
        </div>
      </div>

      <div className="navigator">
        <div className="sublist">
          <div className="head">Umrah</div>
          <div className="links">
            <div className="link">
              <ChevronRightIcon className="arrowIcon" />
              <div className="linkText">Umrah Packages</div>
            </div>
            I<div className="divider"></div>
            <div className="link">
              <ChevronRightIcon className="arrowIcon" />
              <div className="linkText">Speak to an expert</div>
            </div>
            <div className="divider"></div>
          </div>
        </div>

        <div className="sublist">
          <div className="head">Tours</div>
          <div className="links">
            <div className="link">
              <ChevronRightIcon className="arrowIcon" />
              <div className="linkText">Tour Packages</div>
            </div>
            <div className="divider"></div>
          </div>
        </div>

        <div className="sublist">
          <div className="head">Sections</div>
          <div className="links">
            <div className="link">
              <ChevronRightIcon className="arrowIcon" />
              <div className="linkText">Testimonials</div>
            </div>
            <div className="divider"></div>

            <div className="link">
              <ChevronRightIcon className="arrowIcon" />
              <div className="linkText">Contact Us</div>
            </div>
            <div className="divider"></div>
          </div>
        </div>
      </div>

      <div className="privacy">
        <div className="privacyText">
          © 2024 Ocean Breeze Travel. Designed & Developed By{" "}
          <a
            href="https://www.linkedin.com/in/raja-ali-hassan-141b6b19a/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontStyle: "italic",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            RAH
          </a>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 100%;
  width: 100%;

  padding-top: 5%;
  background-color: #f2f2f2;

  .topline {
    height: 09px;
    width: 100%;
    background-color: #de007a;
  }
  .contactHolder {
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #1f4995;
  }
  .contactInner {
    height: 100%;
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .iconHolder {
    background-color: #2b519c;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 15px;
    transition: background-color 0.3s ease;
  }

  .iconHolder:hover {
    background-color: #d90479;
    cursor: pointer;
  }
  .icon {
    font-size: 40px;
    color: #7f93c8;
    transition: background-color 0.3s ease;
  }
  .iconHolder:hover .icon {
    color: white;
  }
  .title {
    font-size: 28px;
    color: #ecffff;
    font-family: "EB Garamond", serif;
  }
  .subtitle {
    font-size: 14px;
    color: #ecffff;
    font-weight: 500;
    opacity: 0.98;
  }
  .contactItem {
    height: 85%;
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .navigator {
    width: 100%;
    height: 100%;
    padding-top: 3%;

    padding-bottom: 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* align-items: center; */
    background-color: #183b7d;
  }
  .privacy {
    width: 100%;
    height: 50px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    /* display */
  }
  .privacyText {
    color: grey;
    letter-spacing: 5px;
    font-size: 13px;
    text-align: center;
  }
  .links {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .link {
    display: flex;
    padding: 10px;
    flex-direction: row;
    margin: 1%;
  }
  .link:hover {
    cursor: pointer;
  }
  .arrowIcon {
    color: white;
    opacity: 0.65;
  }
  .linkText {
    font-size: 18px;
    color: white;
  }
  .sublist {
    width: 20%;
  }
  .head {
    font-size: 30px;
    color: #ecffff;
    font-family: "EB Garamond", serif;
  }
  .divider {
    height: 1px;
    background-color: white;
    opacity: 0.65;
  }
  @media (max-width: 768px) {
  padding-top: 250px;

  .contactInner {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

  }
  .contactItem {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 5%;
  }
  .contactHolder {
    height: 100%;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .textHolder{
    margin-left: 5%;
  }
  .navigator {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .sublist {
    width: 90%;
  }
  }
`;

export default Footer;
